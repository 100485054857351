import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

import fondo from '../../assets/img/landingPage/fondoApps.png';

import icon_free2 from '../../assets/img/landingPage/devStudio/dev-s_01_project.svg'
import icon_dyn2 from '../../assets/img/landingPage/devStudio/dev-s_02_code.svg'
import icon_expo2 from '../../assets/img/landingPage/devStudio/dev-s_03_brunix.svg'

import features_1 from '../../assets/img/landingPage/devStudio/DevStudio_01.png'
import features_2 from '../../assets/img/landingPage/devStudio/DevStudio-02.png'
import features_3 from '../../assets/img/landingPage/devStudio/DevStudio-03.png'
import ReactGA from 'react-ga4'

import logo from '../../assets/img/logos/AVAP_Zero_w.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const IdeProduct = () => {

    useEffect(() => {

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true) {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Dev Studio' });
        }

    }, [])

    useEffect(() => {

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true) {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Dev Studio' });
        }

    }, [])

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // clean favicon 
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Gen AI-Powered No-Code Development</h1>
                    <p className="fl-subtitle">
                        Build and deploy APIs effortlessly with Brunix AI at the core.
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Zero</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            <p className="fl-subtitle-xs mb-3">
                                Gen AI-Powered No-Code Development for Everyone
                            </p>
                            <p className="fl-text">
                                AVAP Zero revolutionizes API development by integrating Brunix, Generative AI, at its core, allowing users to create custom APIs without writing a single line of code. Built on the robust AVAP Framework, it empowers businesses and developers alike to transform ideas into scalable, ready APIs and API products with unprecedented speed.
                                AVAP Zero streamlines workflows, reduces development time, and optimizes app performance—all within an intuitive, drag-and-drop interface.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Brunix AI API Builder</h3>
                            <p className="fl-text">
                                AVAP Zero harnesses the power of Generative AI to streamline API development by automatically generating code, optimizing workflows, and providing intelligent recommendations. This ensures seamless API creation, accelerating deployment while enhancing scalability, security, and performance.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">No-Code, High-Impact API Development</h3>
                            <p className="fl-text">
                                Design and deploy powerful APIs using an intuitive visual interface—no coding required. AVAP Zero simplifies API development while maintaining enterprise-grade capabilities, making it easy for both developers and non-technical users to build and manage APIs efficiently.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">End-to-End Automation & Smart Debugging</h3>
                            <p className="fl-text">
                                From API design to deployment, AVAP Zero automates repetitive tasks, detects potential issues in real-time, and continuously refines API performance. With AI-powered debugging and optimization, developers can focus on innovation while ensuring secure, high-performing APIs.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col mb-5">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Accelerate API Development with Brunix AI
                                </p>
                                <p className="fl-text">
                                    Reduce development time by automating complex API workflows and leveraging Brunix AI to optimize processes. This allows businesses to quickly move from concept to deployment, creating high-quality APIs faster than traditional development methods.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Lower Costs, Higher Efficiency
                                </p>
                                <p className="fl-text">
                                    Eliminate the need for costly development resources while maintaining full control over your API's functionality and scalability. Streamlined development and automation ensure your APIs are efficient, cost-effective, and ready for any workload.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Future-Proof & Scalable API Solutions
                                </p>
                                <p className="fl-text">
                                    Whether you're building simple APIs for a small project or complex, enterprise-level integrations, AVAP Zero delivers flexible and scalable solutions that evolve alongside your business, ensuring long-term performance and adaptability.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default IdeProduct;
