import React, { useEffect} from 'react';
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/cloud/Cloud-home.png';

import icon_free from '../../assets/img/landingPage/cloud/cloud_01_layered.svg'
import icon_dyn from '../../assets/img/landingPage/cloud/cloud_02_universal.svg'
import icon_expo from '../../assets/img/landingPage/cloud/cloud_03_database.svg'

import features_1 from '../../assets/img/landingPage/cloud/Cloud-01.png'
import features_2 from '../../assets/img/landingPage/cloud/Cloud_02.png'
import features_3 from '../../assets/img/landingPage/cloud/Cloud_03.png'

import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP_Cloud_negativo.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'


import lg_cloud from '../../assets/img/logos/AVAP_Cloud_negativo.svg'
import frameTablet from '../../assets/img/landingPage/iPad_16.9.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const CloudProduct = () => {
    const history = useHistory();



    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Cloud Page' });
            }

    }, [])





    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Cloud Page' });
            }

    }, [])



    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">A Native Management Service</h1>
                    <p className="fl-subtitle">
                        Transform the development, management <br /> and security of APIs
                    </p>
                    <a className="glowBtn-dark" href="https://avapcloud.com/business_en" target='_blank'><span className="btnInner"> AVAP Cloud Start for Free</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="text-center pb-5">
                    <img
                        src={dashboard}
                        alt="Platform Placeholder"
                        className="img-fluid mt-4"
                        style={{ width: '80%', maxWidth: '900px', height: 'auto' }}
                    />
                    <h2 className="fl-title m-4">Powerful API Platform</h2>
                    <p className="fl-subtitle-xs mb-3">
                        Manage the entire API lifecycle in a SaaS model from <br /> design to testing and deployment.
                    </p>
                    <p className="fl-text">
                        Powered by AVAP technology, virtuality and artificial intelligence will be in <br /> the core of every API and API product you develop.
                    </p>

                </section>

                {/* Features */}
                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Three layered Infrastructure</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Universal Platform</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Database Control</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Beyond a Proxy</h3>
                            <p className="fl-text">
                                It features a powerful abstraction layer that allows developers to configure and manage server infrastructure
                                and services through an intuitive interface, all while maintaining API format independence.
                                Whether integrating REST, SOAP, or GraphQL APIs, AVAP Cloud enables seamless re-exposure in any desired format.
                                This sets us apart as a robust platform for effortless API transformation and management across any deployment environment.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Comprehensive API Management</h3>
                            <p className="fl-text">
                                Leveraging integrated AVAP Language Servers, AVAP Cloud facilitates the development, maintenance,
                                and utilization of APIs with a specialized virtual language. It includes robust version management, testing, and continuous integration.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Enhanced Backend API Support</h3>
                            <p className="fl-text">
                                AVAP Cloud manages external backend APIs by enforcing security and ensuring consistency.
                                It integrates seamlessly with other APIs and offers flexibility to adjust both the consumption interface
                                and response format. Additionally, it enhances APIs with features like analytics, consumption limits,
                                and monetization.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>

                {/* Key Benefits */}
                <section className="pb-5">
                    <h2 className="fl-title text-center">Key Benefits</h2>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="fl-card grayGradient">
                                    <img src={icn_1} alt="" />
                                    <h4 className="fl-subtitle">Empower Strategic Focus through Delegated Infrastructure Management</h4>
                                    <p className="fl-text ">
                                        AVAP Cloud allows businesses to delegate the complexities of API infrastructure management,
                                        freeing up resources and enabling teams to concentrate on high-impact strategic projects.
                                        This ensures a sharper focus on innovation and growth rather than operational maintenance.
                                    </p>
                                    <img className="rocket" src={rocket} alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="fl-card grayGradient">
                                            <img src={icn_2} alt="" />
                                            <h4 className="fl-subtitle">Simplify Complex Infrastructures with Centralized Control</h4>
                                            <p className="fl-text pb-c">
                                                The platform’s centralized designer streamlines the management of intricate infrastructures,
                                                making it easier to oversee and optimize API deployment. Gain total control of your investments,
                                                ensuring every project is efficiently managed while keeping costs predictable and transparent.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="fl-card grayGradient">
                                            <img src={icn_3} alt="" />
                                            <h4 className="fl-subtitle">Accelerate Development and Optimize Investments</h4>
                                            <p className="fl-text pb-c">
                                                AVAP Cloud reduces operational costs by speeding up API development and deployment,
                                                helping businesses achieve faster time to market. The platform also optimizes infrastructure
                                                investments by enabling effective management of development teams and resources,
                                                ensuring maximum ROI on every project.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* Subs */}
                <div className="bottomWrapper position-relative d-flex justify-content-center mt-8">

                    <section className="container  mb-8" >
                        <div className="row">
                            <div className="col">
                                <h2 className="fl-title text-center">AVAP Cloud Memberships</h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="CloudCard gradient-d">
                                    {/* <img src={lg_cloud} alt="" /> */}
                                    <div className="titleBox alter">
                                        AVAP CLOUD PROFESSIONAL
                                    </div>
                                    <div>
                                        <p className="price">
                                            $75.00
                                        </p>
                                        <span className='priceInfo'>Per month</span>
                                    </div>

                                    <div className="descBox">
                                        Membership that allows you to explore and start taking advantage of AVAP technology.
                                    </div>

                                    <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapcloud.com/professional_en"><span className="btnInner">  Start for Free</span></a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="CloudCard gradient-d">

                                    <div className="titleBox alter">
                                        AVAP CLOUD BUSINESS
                                    </div>
                                    <div>
                                        <p className="price">
                                            $250.00
                                        </p>
                                        <span className='priceInfo'>Per month</span>
                                    </div>
                                    <div className="descBox">
                                        Pre-configured AVAP infrastructure deployment, enabling you to concentrate on developing and publishing your APIs.
                                    </div>

                                    <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapcloud.com/business_en"><span className="btnInner">  Start for Free</span></a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="CloudCard gradient-d">
                                    {/* <img src={lg_cloud} alt="" /> */}
                                    <div className="titleBox alter">
                                        AVAP CLOUD ENTERPRISE
                                    </div>
                                    <div>
                                        <p className="price">
                                            Custom Pricing
                                        </p>
                                        <span className='priceInfo'>Billed Annually</span>
                                    </div>
                                    <div className="descBox">
                                        Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.
                                    </div>

                                    <a className="glowBtn-dark alter m-0" target='_blank' onClick={() => history.push('/contact')}><span className="btnInner"> Contact Us</span></a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>



                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                    <section className="container py-5">
                        <div className="row align-items-center mb-5">
                            <div className="col-md-6">
                                {/* <h2 className="fw-bold">Key Benefits</h2> */}
                                <h3 className="fw-bold mb-5">Getting Started</h3>
                                <ul className="startedList">
                                    <li>   <img src={user} className="mr-2" alt="" />
                                        <a href="https://avapid.com/login" target='_blank'>Create your AVAP ID</a>
                                    </li>

                                    <li> <img src={download} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view12-GET%20STARTED_Downloads/0" target='_blank'>Download basic software</a>

                                    </li>
                                    <li>
                                        <img src={key} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view_aefmvsc_cli-GET%20STARTED_Dev_Token/0" target='_blank'>Create your Developer Token</a>
                                    </li>
                                    <li>
                                        <img src={doc} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view_cloud-GET%20STARTED_Projects/0" target='_blank'>Create your first API Project</a>
                                    </li>
                                </ul>
                                <a className="glowBtn-dark m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner">  Developer Portal →</span></a>
                            </div>
                            <div className="col-md-6 ">
                                <div className="ytWrapper2">
                                    <img src={frameTablet} alt="" className="frameTablet" />
                                    <iframe className='fl-video-container' src="https://www.youtube.com/embed/LlVcKzcM3uc?si=RbgB1V_dJPcusm5y&amp;controls=0&rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>


            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default CloudProduct;
